import * as React from "react"
import { Link } from "gatsby"
import TimeAgo from "timeago-react"
import { timeFormatter } from "../../../services/timeHelper"
import TagListView from "./tagListView"
import {
  getTaskUrgencyClass,
  getTaskUrgencyIcon,
  getTaskStatusClass,
  getTaskStatusIcon,
} from "./taskListHelper"
import { taskStatusesData, taskUrgencyData, TASK_DATE_FORMAT } from "../taskHelper"
import { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import moment from "moment"

const TaskListRow = ({
  task,
  setDeleteTaskRow,
  setSelectedTasks,
  selectedTasks,
  setArchivedTaskRow,
  transactionTypeId,
}) => {
  const taskId = "T_ID" + task.id

  const [statusClass, setStatusClass] = useState(""),
    [statusIcon, setStatusIcon] = useState(""),
    [urgency, setUrgency] = useState(""),
    [urgencyClass, setUrgencyClass] = useState(""),
    [urgencyIcon, setUrgencyIcon] = useState(""),
    [taskStatusesNewData, setTaskStatusesNewData] = useState(taskStatusesData),
    [isDefaultStatusData, setIsDefaultStatusData] = useState(true),
    [defaultContactImage, setDefaultContactImage] = useState("StaticImages/avatar.gif")

  const updateTaskStatus = (taskStatusValue, taskId, transactionTypeStatusId) => {
    let taskStatusObject = {}

    if (transactionTypeStatusId) {
      taskStatusObject.transactionTypeStatus = transactionTypeStatusId
    } else {
      taskStatusObject.status = taskStatusValue
    }

    AxiosInstance.post(`task/update-status/${taskId}`, taskStatusObject)
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  const updateTaskUrgency = (taskUrgencyValue, taskId) => {
    AxiosInstance.post(`task/update-urgency/${taskId}`, {
      urgency: taskUrgencyValue,
    })
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  const getTransactionDetail = transactionTypeId => {
    if (transactionTypeId) {
      return new Promise(async () => {
        await AxiosInstance.get(`/settings/transaction-type/detail/${transactionTypeId}`).then(function (
          response
        ) {
          if (response.status === 200) {
            const transactionType = response.data.type
            setTaskStatusesNewData(transactionType.statuses)
            setIsDefaultStatusData(false)
          }
        })
      })
    }
  }

  return (
    <tr>
      <td className="tableColChkDel">
        <div className="tableColChkDelBox">
          <input
            type="checkbox"
            name="fileid[]"
            id={taskId}
            className="css-checkbox"
            onChange={e => {
              if (e.target.checked) {
                if (!selectedTasks.includes(task.id)) {
                  setSelectedTasks([...selectedTasks, task.id])
                }
              } else {
                const selectedFiles = selectedTasks.filter(select => select !== task.id)
                selectedFiles === [] ? setSelectedTasks([]) : setSelectedTasks(selectedFiles)
              }
            }}
          />
          <label htmlFor={taskId} className="css-checkbox-label" />
          <span
            className="tableColChkSpan transactionTypeIconColor"
            style={{
              color: task.transactionType?.iconColor,
              backgroundColor: task.transactionType?.iconBackgroundColor,
            }}
          >
            <i className={`bi bi-${task.transactionType ? task.transactionType.icon : "check-square"}`} />
          </span>
        </div>
      </td>
      <td className="nowrap">
        <Link to={`/jobs/detail/${task.id}`}>{task.title}</Link>
        &nbsp;
        {task.tags.length > 0 && (
          <TagListView tags={task.tags} taskId={task.id} transactionTypeId={transactionTypeId} />
        )}
        {task.calendars[0] && (
          <div className="iconTextBoxContainer pinkBox ms-1">
            <div className="iconTextBox">
              <span className="iconTextBoxIcon">
                <i className="bi bi-calendar3" />
              </span>
              <span className="iconTextBoxText">
                {task.calendars[0].startTime
                  ? moment(task.calendars[0]?.startTime).format(TASK_DATE_FORMAT)
                  : ""}
              </span>
            </div>
          </div>
        )}
        {task.taskContacts.length > 0 && (
          <ul className="user-card-list ms-1">
            {task.taskContacts.map(
              (taskContactData, taskContactIndex) =>
                taskContactData.contact.name && (
                  <li key={taskContactIndex}>
                    <Link to={`/contacts/detail/${taskContactData.contact?.id}`}>
                      <img
                        src={`${process.env.GATSBY_WRANGLER_URL}${
                          taskContactData.contact?.profilePic
                            ? taskContactData.contact.profilePic
                            : defaultContactImage
                        }`}
                        alt=""
                        className="user_avatar"
                      />
                      {`${taskContactData.contact?.name}${
                        taskContactData.settingRole ? `, ${taskContactData.settingRole.role}` : ""
                      }`}
                    </Link>
                  </li>
                )
            )}
          </ul>
        )}
        {task?.metaJson?.money_details?.single_price && (
          <div className="SwiftCloudMoneyTags">
            <ul>
              <li className="CompanyOnly Everyone tag-blue">
                <a>${task.metaJson.money_details.single_price}</a>
              </li>
            </ul>
          </div>
        )}
      </td>

      <td className="nowrap">
        {task.urgency && (
          <>
            <button
              type="button"
              className={`btn urgencyStatus_${
                urgencyClass ? urgencyClass : getTaskUrgencyClass(task.urgency)
              }`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className={`bi bi-${urgencyIcon ? urgencyIcon : getTaskUrgencyIcon(task.urgency)}`} />{" "}
              {urgency ? urgency : task.urgency}
            </button>
            <ul className={`dropdown-menu hiddenStatus hideUrgencyStatus`}>
              {taskUrgencyData.map((taskUrgency, index) => (
                <li
                  key={index}
                  onClick={e => {
                    setUrgency(taskUrgency.LABEL)
                    setUrgencyClass(taskUrgency.CLASS)
                    setUrgencyIcon(taskUrgency.ICON)
                    updateTaskUrgency(taskUrgency.LABEL, task.id)
                    e.target.closest("ul.hideUrgencyStatus").classList.remove("show")
                  }}
                >
                  <a className="dropdown-item">
                    <span className={`urgencyStatus_${taskUrgency.CLASS}`}>
                      <i className={`bi bi-${taskUrgency.ICON}`} />
                    </span>{" "}
                    {taskUrgency.LABEL}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </td>

      <td className="text-end d-none d-lg-table-cell">
        <div className="lastActivityBox">
          <TimeAgo datetime={timeFormatter(task.updatedAt)} />
        </div>

        <div className="statusActionBox">
          <button
            className="btn btnTaskStatus btn-dark"
            title="Archive"
            onClick={() => {
              setArchivedTaskRow(task.id)
            }}
          >
            <i className="bi bi-archive-fill" />
          </button>
          <button
            type="button"
            className={`btn btnTaskStatus taskStatus_${
              statusClass
                ? statusClass
                : task.transactionTypeStatus?.id
                ? "defaultStatusColor"
                : getTaskStatusClass(task.status)
            }`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => getTransactionDetail(task.transactionType?.id)}
          >
            <i
              className={`bi bi-${
                statusIcon
                  ? statusIcon
                  : task.transactionTypeStatus?.icon
                  ? task.transactionTypeStatus?.icon
                  : getTaskStatusIcon(task.status)
              }`}
            />
          </button>
          <ul className={`dropdown-menu hiddenStatus`}>
            {taskStatusesNewData.map((taskStatus, index) => (
              <li
                key={index}
                value={taskStatus.label}
                onClick={e => {
                  setStatusClass(taskStatus.class)
                  setStatusIcon(taskStatus.icon)
                  updateTaskStatus(taskStatus.label, task.id, taskStatus.id)
                  e.target.closest("ul.hiddenStatus").classList.remove("show")
                }}
              >
                <a className="dropdown-item">
                  <span
                    className={`${
                      isDefaultStatusData ? `taskStatus_${taskStatus.class}` : "transactionTypeIconColor"
                    }`}
                  >
                    <i className={`bi bi-${taskStatus.icon}`} />
                  </span>{" "}
                  {taskStatus.label}
                </a>
              </li>
            ))}
          </ul>

          <a
            className="btn btn-delete"
            data-toggle="tooltip"
            data-placement="bottom"
            data-animation="false"
            title="Delete"
            onClick={() => {
              setDeleteTaskRow(task.id)
            }}
          >
            <i className="bi bi-trash-fill" />
          </a>
        </div>
      </td>
    </tr>
  )
}

export default TaskListRow
